import React from "react";
import NavBar from "./components/NavBar.js";

function App() {
  return (
      <>
        <NavBar />
        <div>
          <h1>This is Amin from MERN!!!</h1>
        </div>
      </>
  );
}

export default App;

